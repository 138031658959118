body {
  background-color: #282c34;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

button {
  font-size: 40px;
  margin-right: 20px;
  padding: 20px;
  margin: 10px 0;
}

#instructions {
  background-color: white;
  width: 500px;
  min-height: 500px;
  text-align: left;
  padding: 20px;
}

.error {
  padding: 40px 10px;
  color: red;
  font-size: 20px;
  background-color: white;
}

select {
  font-size: 40px;
  margin: 20px;
  padding: 10px;
}

#resolution {
  position: fixed;
  left: 0;
  top: 0;
  background: white;
  color: black;
  padding: 10px;
}

.video-view {
  width: 100vw;
  height: 100vh;
}

.inverted { filter: invert() }

.gray-scale-1 { filter: grayscale(100%); }

.contrast-1 { filter: contrast(125%); }
.contrast-2 { filter: contrast(150%); }
.contrast-3 { filter: contrast(175%); }
.contrast-4 { filter: contrast(200%); }
.contrast-5 { filter: contrast(225%); }
.contrast-6 { filter: contrast(250%); }
.contrast-7 { filter: contrast(275%); }
.contrast-8 { filter: contrast(300%); }
.contrast-9 { filter: contrast(325%); }


.bright-1 { filter: brightness(125%); }
.bright-2 { filter: brightness(150%); }
.bright-3 { filter: brightness(175%); }
.bright-4 { filter: brightness(200%); }
.bright-5 { filter: brightness(225%); }
.bright-6 { filter: brightness(250%); }
.bright-7 { filter: brightness(275%); }
.bright-8 { filter: brightness(300%); }
.bright-9 { filter: brightness(325%); }

dt {
  float: left;
}